import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VExpansionPanels,{staticClass:"SubExpansionComponent"},[_c(VExpansionPanel,{on:{"change":_vm.deepDisabled}},[_c(VExpansionPanelHeader,{staticClass:"ExpansionTitle d-flex justify-start",attrs:{"expand-icon":""},on:{"click":_vm.changeSubExpansion},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('div',{staticClass:"subIconCont d-flex justify-start align-center"},[_c(VIcon,{staticClass:"iconExpand mr-1"},[_vm._v(" "+_vm._s(_vm.iconSubExpansion)+" ")]),_c('div',{staticClass:"SubExpansionTitle"},[_vm._v("Risk Profile")])],1)]},proxy:true}])}),_c(VExpansionPanelContent,[_c('div',{staticClass:"ExpandContent"},[_c('div',{staticClass:"TitleTextArea"},[_vm._v("Offer Comments")]),_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.boundEng.riskProfileComments.$model),expression:"$v.boundEng.riskProfileComments.$model",modifiers:{"trim":true}}],domProps:{"value":(_vm.$v.boundEng.riskProfileComments.$model)},on:{"blur":[function($event){_vm.SET_BOUND_ENG('riskProfileComments', this);
            _vm.checkField('riskProfileComments');},function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.boundEng.riskProfileComments, "$model", $event.target.value.trim())}}}),_c('div',{staticClass:"InputsCont d-flex justify-start align-center"},[_c('div',{staticClass:"InputCont"},[_c(VSelect,{attrs:{"items":_vm.typeClause,"label":"Type of clause","item-text":"data","item-value":"id","clearable":"","disabled":_vm.typeClause.length === 0},on:{"blur":function($event){_vm.SET_BOUND_ENG('riskProfileClause', this);
                _vm.checkField('riskProfileClause');}},model:{value:(_vm.$v.boundEng.riskProfileClause.$model),callback:function ($$v) {_vm.$set(_vm.$v.boundEng.riskProfileClause, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.boundEng.riskProfileClause.$model"}})],1),_c('div',{staticClass:"InputCont"},[_c(VSelect,{attrs:{"items":_vm.exposure,"label":"Exposure by neighborhood","item-text":"data","item-value":"id","clearable":"","disabled":_vm.exposure.length === 0},on:{"blur":function($event){_vm.SET_BOUND_ENG('riskProfileExposure', this);
                _vm.checkField('riskProfileExposure');}},model:{value:(_vm.$v.boundEng.riskProfileExposure.$model),callback:function ($$v) {_vm.$set(_vm.$v.boundEng.riskProfileExposure, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.boundEng.riskProfileExposure.$model"}})],1),_c('div',{staticClass:"InputCont"},[_c(VSelect,{attrs:{"items":_vm.housekeeping,"label":"Housekeeping","item-text":"data","item-value":"id","clearable":"","disabled":_vm.housekeeping.length === 0},on:{"blur":function($event){_vm.SET_BOUND_ENG('riskProfileHousekeeping', this);
                _vm.checkField('riskProfileHousekeeping');}},model:{value:(_vm.$v.boundEng.riskProfileHousekeeping.$model),callback:function ($$v) {_vm.$set(_vm.$v.boundEng.riskProfileHousekeeping, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.boundEng.riskProfileHousekeeping.$model"}})],1)])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }